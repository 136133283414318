<template>
    <div class="pt-7 container-fluid">
        <div class="col-xl-8">
            <card>
                <b-row align-v="center" slot="header">
                    <b-col cols="8">
                        <h3 class="mb-0">{{$t('general.profile')}}</h3>
                    </b-col>
                </b-row>
                <admin-profile :profile="profile" v-if="userType=='admin'" />
                <doctor-profile :profile="profile" v-if="userType=='doctor'" />
                <patient-profile :profile="profile" v-if="userType=='patient'" />
            </card>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AdminProfile from './Admin.vue';
import DoctorProfile from './Doctor.vue';
import PatientProfile from './Patient.vue';

let self;

export default {
    components: {
        AdminProfile,
        DoctorProfile,
        PatientProfile
    },
    computed: {
        ...mapGetters({ profile: 'getProfile', userType: 'getUserType' })
    },
    methods: {},
    async mounted() {
        self = this;
        self.user = _.merge(self.user, self.profile);
    },
    watch: {
        profile: val => {
            self.user = _.merge(self.user, val);
        }
    }
};
</script>
