<template>
    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
        <b-form @submit.prevent="onSubmit" autocomplete="off" v-loading="loading">
            <h6 class="heading-small text-muted mb-4 patient">{{$t('profile.user_information')}}</h6>
            <div class="pl-lg-4">
                <b-row>
                    <b-col lg="4">
                        <base-input type="text" name="name" :nameAs="$t('profile.name_lastname')" :label="$t('profile.name_lastname')" :placeholder="$t('profile.name_lastname')" v-model="user.name" required>
                        </base-input>
                    </b-col>
                    <b-col lg="4" v-show="!user.nontc">
                        <base-input type="text" name="identity" :nameAs="$t('profile.identity')" :label="$t('profile.identity')" :placeholder="$t('profile.identity')" v-model="user.identity" :rules="{required:true, numeric:true, min:11,max:11}">
                        </base-input>
                    </b-col>
                    <b-col lg="4">
                        <label class="form-control-label">{{$t('profile.phone')}}</label>
                        <VuePhoneNumberInput id="phoneNumber" v-model="user.maskPhone" required class="mb-2" :translations="{example: $t('general.example'), countrySelectorError: $t('error.select_country'), countrySelectorLabel: $t('general.country_code'), phoneNumberLabel: $t('general.phone_number')}" :defaultCountryCode="user.defaultCountryCode" show-code-on-list @update="onPhone" :no-flags="true" />
                    </b-col>
                    <b-col lg="4">
                        <base-input type="email" name="email" :nameAs="$t('profile.email')" :label="$t('profile.email')" :placeholder="$t('profile.email')" v-model="user.email" required>
                        </base-input>
                    </b-col>
                    <b-col lg="4">
                        <label class="form-control-label">{{$t('profile.password')}}</label>
                        <div class="password-div">
                            <base-input :type="showPassword?'text':'password'" name="password" :placeholder="$t('profile.password')" v-model="user.password" :rules="'min:6'">
                            </base-input>
                            <div class="showPassword" @click="showPassword = showPassword !== true">
                                <span v-if="!showPassword">
                                    <i class="fas fa-eye"></i>
                                </span>
                                <span v-else>
                                    <i class="fas fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>
                        <small class="mt--3 dblock">{{$t('profile.pass_desc')}}</small>
                    </b-col>
                    <b-col lg="4">
                        <label class="form-control-label">{{$t('profile.nontc_info')}}</label>
                        <div>
                            <b-form-checkbox v-model="user.nontc" class="switchCheck" name="check-button" @change="nonTc" switch>
                                {{user.nontc ? $t('general.yes') : $t('general.no')}}
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr class="my-4">
            <div class="text-right">
                <base-button type="primary" native-type="submit" :disabled="invalid || user.phone==''">{{$t('save')}}</base-button>
            </div>
        </b-form>
    </validation-observer>
</template>

<script>
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { PATIENT_UPDATE, USER_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import VuePhoneNumberInput from '@/components/PhoneInput';
let self;
export default {
    components: { VuePhoneNumberInput },
    data() {
        return {
            loading: false,
            user: {
                uuid: '',
                name: '',
                identity: '',
                maskPhone: '',
                defaultCountryCode: '',
                countryCode: '',
                phone: '',
                email: '',
                password: '',
                nontc: false
            },
            showPassword: false
        };
    },
    props: {
        profile: null
    },
    methods: {
        nonTc() {
            this.$set(self.user, 'identity', self.user.nontc ? '11111111110' : '');
        },
        onPhone(payload) {
            if (payload.isValid) {
                this.$set(self.user, 'countryCode', payload.countryCallingCode);
                this.$set(self.user, 'phone', payload.nationalNumber);
            } else {
                this.$set(self.user, 'countryCode', '');
                this.$set(self.user, 'phone', '');
            }
        },
        onSubmit() {
            let uuid = self.user.uuid;
            let user = _.pickBy(self.user, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            if (user.password && user.password.length < 6) {
                self.$notify({ type: 'warning', message: self.$i18n.t('error.password_length') });
                return false;
            }

            self.loading = true;
            self.$store
                .dispatch(PATIENT_UPDATE, { uuid, patient: user })
                .then(resp => {
                    self.loading = false;
                    self.user.password = '';
                    self.$store.dispatch(USER_REQUEST);
                    self.$notify({ type: 'success', message: self.$i18n.t('success.profile_update') });
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        }
    },
    watch: {
        profile: val => {
            self.user = _.merge(self.user, val);

            if (val.phone) {
                let parsePhone = findPhoneNumbersInText('+' + self.user.phone.toString());
                if (parsePhone && parsePhone[0]) {
                    let country = parsePhone[0].number.country;
                    let phone = parsePhone[0].number.nationalNumber;
                    let countryCode = parsePhone[0].number.countryCallingCode;
                    self.$set(self.user, 'defaultCountryCode', country);
                    self.$set(self.user, 'countryCode', countryCode);
                    self.$set(self.user, 'maskPhone', phone);
                }
            }
        }
    },
    created() {
        self = this;
        if (self.profile) {
            self.user = _.merge(self.user, self.profile);

            if (self.profile.phone) {
                let parsePhone = findPhoneNumbersInText('+' + self.profile.phone.toString());
                if (parsePhone && parsePhone[0]) {
                    let country = parsePhone[0].number.country;
                    let phone = parsePhone[0].number.nationalNumber;
                    let countryCode = parsePhone[0].number.countryCallingCode;
                    self.$set(self.user, 'defaultCountryCode', country);
                    self.$set(self.user, 'countryCode', countryCode);
                    self.$set(self.user, 'maskPhone', phone);
                }
            }
        }
    }
};
</script>
