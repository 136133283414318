<template>
    <div class="container-fluid">
        <div class="text-center">
            <b-button variant="link" class="text-primary" @click="handleEdit(doctors[0])">
                {{ $t('general.edit') }}
            </b-button>
        </div>
        <div class="profile-header">
            <b-avatar variant="primary" v-if="!doctors[0].photo"></b-avatar>
            <b-avatar v-else :src="imgUrl+doctors[0].photo"></b-avatar>
            <h4 class="text-center">{{doctors[0].title}} {{doctors[0].name}}</h4>
            <small v-html="$options.filters.showBranch(doctors[0].branch)"></small>
            <hr class="my-3">
            <small>
                <b-row>
                    <b-col md="4">
                        <strong>{{$t('general.email')}} : </strong> {{doctors[0].email}}
                    </b-col>
                    <b-col md="4">
                        <strong>{{$t('general.phone')}} : </strong>  <span v-if="doctors[0].phone&&doctors[0].phone.trim()!=''">+{{doctors[0].phone}}</span>
                    </b-col>
                    <b-col md="4">
                        <strong>{{$t('general.city')}} : </strong>  {{doctors[0].city | showCity}}
                    </b-col>
                </b-row>
            </small>
        </div>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid, errors}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.uuid ? $t('doctor.edit') : $t('doctor.add')}}</h6>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading" novalidate>
                    <hr>
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1 ovisible">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.pinfo variant="default">{{$t('general.personal_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="pinfo" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="2">
                                            <base-input type="text" name="title" :nameAs="$t('doctor.title')" :label="$t('doctor.title')" :placeholder="$t('doctor.title')" v-model="modal.data.title">
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input type="text" name="name" :nameAs="$t('general.name')" :label="$t('general.name')+'*'" :placeholder="$t('general.name')" @input="generateSeo('name')" v-model="modal.data.name" required>
                                            </base-input>
                                        </b-col>
                                        <b-col md="4">
                                            <base-input :label="$t('general.known_languages')+'*'" name="branch" :nameAs="$t('general.known_languages')" :rules="'required'">
                                                <el-select v-model="modal.data.languages" :placeholder="$t('general.select')" filterable multiple>
                                                    <el-option v-for="(item, key) in languages" :key="key" :label="item.nativeName" :value="item.code"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="4">
                                            <label class="form-control-label">{{$t('profile.phone')}}*</label>
                                            <VuePhoneNumberInput 
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                v-model="modal.data.maskPhone" 
                                                required 
                                                class="mb-2" 
                                                :translations="{
                                                    example: $t('general.example'), countrySelectorError: $t('error.select_country'), countrySelectorLabel: $t('general.country_code'), phoneNumberLabel: $t('general.phone_number')
                                                }" 
                                                :defaultCountryCode="modal.data.defaultCountryCode"
                                                show-code-on-list
                                                @update="onPhone"
                                                no-flags />
                                        </b-col>
                                        <b-col md="4">
											<label class="form-control-label">{{ $t('profile.officePhone') }}*</label>
											<VuePhoneNumberInput
												id="officeNumber"
                                                name="officeNumber"
												v-model="modal.data.officePhone.number"
												required
												class="mb-2"
												:translations="{
													example: $t('general.example'),
													countrySelectorError: $t('error.select_country'),
													countrySelectorLabel: $t('general.country_code'),
													phoneNumberLabel: $t('general.phone_number')
												}"
												:defaultCountryCode="modal.data.defaultCountryCode"
												show-code-on-list
												@update="onOfficePhone"
												no-flags
											/>
										</b-col>
                                        <b-col md="4">
                                            <base-input type="email" name="email" :nameAs="$t('profile.email')" :label="$t('profile.email')+'*'" :placeholder="$t('profile.email')" v-model="modal.data.email" required>
                                            </base-input>
                                        </b-col>
                                        <b-col md="4">
											<base-input
												type="text"
												name="webSite"
												:nameAs="$t('profile.website')"
												:label="$t('profile.website') + '*'"
												:placeholder="$t('profile.website')"
												v-model="modal.data.webSite"
											>
											</base-input>
										</b-col>
                                        <b-col md="4">
                                            <label class="form-control-label">{{$t('general.password')}}*
                                                <template v-if="profile.superadmin&&modal.data.temp">
                                                    <small>(
                                                        <strong>{{modal.data.temp}}</strong> )</small>
                                                </template>
                                            </label>
                                            <base-input type="password" name="password" :nameAs="$t('general.password')" :placeholder="$t('general.password')" v-model="modal.data.password" v-if="modal.data.uuid" :rules="'min:6'"></base-input>
                                            <base-input type="password" name="password" :nameAs="$t('general.password')" :placeholder="$t('general.password')" v-model="modal.data.password" :rules="'required|min:6'" v-else></base-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group>
                                                <b-tabs content-class="mt-3">
                                                    <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                                                        <validation-provider :rules="'max:200'" :name="$t('general.short_desc')" v-slot="{ errors }">
                                                            <label class="form-control-label">{{$t('general.short_desc')}}</label>
                                                            <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.short_desc')" rows="5" no-resize v-model="modal.data.descText[lang]" @input.native="clearSpace('descText', lang)" @blur="clearSpace('descText', lang)" @keyup="clearSpace('descText', lang)"></b-form-textarea>
                                                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </validation-provider>
                                                        <small class="text-default">Kısa tanıtım yazısı doktor listeleme ekranında yer alacaktır. Düze metin olarak maksimum 200 karakter olmalıdır.</small>
                                                        <hr class="my-2">
                                                        <label class="form-control-label">{{$t('general.doctor_desc')}}</label>
                                                        <quill-editor v-model="modal.data.desc[lang]" :options="editorOption" />
                                                    </b-tab>
                                                </b-tabs>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.vocational variant="default">{{ $t('general.vocational_info') }}</b-button>
							</b-card-header>
							<b-collapse id="vocational" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-row>
										<b-col md="12">
                                            <VueBootstrapTypeahead
												ref="selectedDisease"
												v-model="queryDiseases"
												:data="diseases"
												:serializer="s => getLangItem(s.name)"
												@hit="selectedDisease=$event"
												@input="inputDisease"
												:placeholder="$t('general.disease_interest')"
											>
												<template slot="append">
													<b-button @click="addDisease" variant="primary" v-if="selectedDisease">
													{{$t('general.add')}}
													</b-button>
												</template>
											</VueBootstrapTypeahead>
											<div class="mt-1">
												<b-badge variant="success"  v-for="(item, index) in modal.data.diseases" :key="index" class="ml-1">
														{{getLangItem(item.name)}} <b-link @click="removeDisease(item._id)"><i class="fal fa-times"></i></b-link>
												</b-badge>
											</div>
											<hr>
											<VueBootstrapTypeahead
												ref="selectedTreatment"
												v-model="queryTreatments"
												:data="treatments"
												:serializer="s => getLangItem(s.name)"
												@hit="selectedTreatment=$event"
												@input="inputTreatment"
												:placeholder="$t('general.treatments')"
											>
												<template slot="append">
													<b-button @click="addTreatment" variant="primary" v-if="selectedTreatment">
													{{$t('general.add')}}
													</b-button>
												</template>
											</VueBootstrapTypeahead>
											<div class="mt-1">
												<b-badge variant="success"  v-for="item in modal.data.treatments" :key="item._id" class="ml-1">
														{{getLangItem(item.name)}} <b-link @click="removeTreatment(item._id)"><i class="fal fa-times"></i></b-link>
												</b-badge>
											</div>
                                            <hr>
											<base-input
												type="text"
												:label="$t('general.diploma_institution')"
												:placeholder="$t('general.schoolname')"
												v-model="modal.data.vocational.universityOfDiploma"
												prependIcon="fas fa-graduation-cap"
											>
											</base-input>

											<base-input
												type="text"
												:label="$t('general.specialization_institution')"
												:placeholder="$t('general.schoolname')"
												v-model="modal.data.vocational.institutionOfSpecialization"
												prependIcon="fas fa-graduation-cap"
											>
											</base-input>

											<label for="example-input">{{$t('general.service_start')}}</label>
											<b-input-group class="mb-3">
												<b-form-input
													id="example-input"
													v-model="modal.data.vocational.careerStart"
													type="text"
													placeholder="DD.MM.YYYY"
													autocomplete="off"
												></b-form-input>
											</b-input-group>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.address variant="default">{{$t('general.address_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="address" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="6">
                                            <base-input type="text" name="address" :nameAs="$t('general.address')" :label="$t('general.address')" :placeholder="$t('general.address')" v-model="modal.data.address">
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input :label="$t('general.country')+'*'" name="country" :nameAs="$t('general.country')" :rules="'required'">
                                                <el-select v-model="modal.data.country" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                                    <el-option v-for="(item, key) in countries" :key="key" :label="item.name" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input :label="$t('general.city')+'*'" name="city" :nameAs="$t('general.city')" :rules="'required'">
                                                <el-select v-model="modal.data.city" filterable :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" :placeholder="$t('general.select')" @change="changeCity" required>
                                                    <el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col>
                                            <div class="simple-map">
                                                <GmapMap :center.sync="center" :options="mapOptions" map-type-id="terrain" @click="markerDrag">
                                                    <GmapMarker :position="modal.data.location" :clickable="true" :draggable="true" @dragend="markerDrag" v-if="modal.data.location.lat>0" />
                                                </GmapMap>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1" v-if="modal.data.uuid">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.photos variant="default">{{ $t('general.photos') }}</b-button>
							</b-card-header>
							<b-collapse id="photos" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-form-file
										multiple
										accept="image/jpeg, image/png"
										size="md"
										v-model="photos.list"
										:placeholder="$t('general.select_img')"
										:drop-placeholder="$t('general.drop_file')"
										:browse-text="$t('general.choose')"
										ref="photos"
									>
										<template slot="file-name" slot-scope="{names}">
											<b-badge variant="dark">{{ names[0] }}</b-badge>
											<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
										</template>
									</b-form-file>
									<p>
										<br />
										<base-button type="primary" size="sm" @click="uploadPictures(modal.data.uuid, 'photos')" :disabled="photos.list.length==0">{{
											$t('general.upload')
										}}</base-button>
									</p>

									<b-row>
										<b-col md="3" v-for="(picture, index) in modal.data.photos" :key="index">
											<b-card no-body class="mt-2">
												<img :src="imgUrl + picture" alt="" />
												<template #footer>
													<base-button type="danger" block size="sm" @click="removePicture(modal.data.uuid, picture, 'photos')">{{
														$t('general.delete')
													}}</base-button>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1" v-if="modal.data.uuid">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.diplomasAndCertificates variant="default">{{ $t('general.diplomasAndCertificates') }}</b-button>
							</b-card-header>
							<b-collapse id="diplomasAndCertificates" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-form-file
										multiple
										accept="image/jpeg, image/png"
										size="md"
										v-model="diplomasAndCertificates.list"
										:placeholder="$t('general.select_img')"
										:drop-placeholder="$t('general.drop_file')"
										:browse-text="$t('general.choose')"
										ref="diplomasAndCertificates"
									>
										<template slot="file-name" slot-scope="{names}">
											<b-badge variant="dark">{{ names[0] }}</b-badge>
											<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
										</template>
									</b-form-file>
									<p>
										<br />
										<base-button type="primary" size="sm" @click="uploadPictures(modal.data.uuid, 'diplomasAndCertificates')" :disabled="diplomasAndCertificates.list.length==0">{{
											$t('general.upload')
										}}</base-button>
									</p>

									<b-row>
										<b-col md="3" v-for="(picture, index) in modal.data.diplomasAndCertificates" :key="index">
											<b-card no-body class="mt-2">
												<img :src="imgUrl + picture" alt="" />
												<template #footer>
													<base-button
														type="danger"
														block
														size="sm"
														@click="removePicture(modal.data.uuid, picture, 'diplomasAndCertificates')"
														>{{ $t('general.delete') }}</base-button
													>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.conversation variant="default">{{$t('general.conversation_settings')}}</b-button>
                            </b-card-header>
                            <b-collapse id="conversation" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.conversation_office')}}</label>
                                            <b-form-checkbox v-model="modal.data.office" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.office ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.conversation_online')}}</label>
                                            <b-form-checkbox v-model="modal.data.online" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.online ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.social variant="default">{{$t('general.social_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="social" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Facebook" :placeholder="$t('general.account_url')" v-model="modal.data.social.facebook" :prependIcon="'fab fa-facebook'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Instagram" :placeholder="$t('general.account_url')" v-model="modal.data.social.instagram" :prependIcon="'fab fa-instagram'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Twitter" :placeholder="$t('general.account_url')" v-model="modal.data.social.twitter" :prependIcon="'fab fa-twitter'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Youtube" :placeholder="$t('general.account_url')" v-model="modal.data.social.youtube" :prependIcon="'fab fa-youtube'">
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <hr>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modalClose" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
        <modal :show.sync="photoModal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="photoModalClose">
            <h6 slot="header" class="modal-title">{{$t('general.image_upload')}}</h6>
            <div class="imgSection text-center" v-loading="photoModal.loading">
                <div class="emptyPhoto" v-if="!photoModal.data.photo">
                    <i class="fas fa-user"></i>
                </div>
                <div class="profilPhoto" v-else>
                    <img :src="imgUrl+photoModal.data.photo" alt="">
                </div>
                <b-form-file accept="image/jpeg, image/png" size="sm" v-model="photoModal.data.upload" :placeholder="$t('general.select_img')" :drop-placeholder="$t('general.drop_file')" :browse-text="$t('general.choose')" ref="file" @change="handleFileUpload"></b-form-file>
                <p><br>
                    <base-button type="primary" size="sm" v-if="photoModal.data.upload" @click="uploadPhoto">{{$t('general.upload')}}</base-button>
                </p>
            </div>
            <base-button type="danger" size="sm" class="ml-auto float-right" @click="photoModalClose">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { findPhoneNumbersInText } from 'libphonenumber-js';
import VuePhoneNumberInput from '@/components/PhoneInput';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import getSlug from 'speakingurl';
import _ from 'lodash';
//import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { CLINIC_REQUEST, DOCTOR_REQUEST, DOCTOR_UPDATE, DOCTOR_ADD, DOCTOR_DELETE, DOCTOR_UPLOAD } from '@/store/modules/define';
import { handleError } from '@/utils/func';
//import { getCookie } from '@/utils/cookie';
import { setTimeout } from 'timers';

let self;
let emptyModal = {
    status: true,
	office: true,
	online: true,
	vonage: true,
	title: '',
	name: '',
	seo: '',
	gender: 'male',
	branch: '',
	clinic: '',
	institution: 'private',
	desc: {},
	descText: {},
	email: '',
	photos: [],
	diplomasAndCertificates: [],
	defaultCountryCode: null,
	countryCode: '',
	phone: '',
	maskPhone: '',
	officePhone: {
		code: '',
		number: ''
	},
	country: '6007eee0aaf082368c83d163',
	city: '',
	address: '',
	webSite: '',
	location: {lat: 0, lng: 0},
	password: '',
	vocational: {
		universityOfDiploma: '',
		institutionOfSpecialization: '',
		careerStart: ''
	},
    diseases: [],
    treatments: [],
	social: {
		facebook: '',
		instagram: '',
		twitter: '',
		youtube: ''
	},
    medisoft: {
        id: '',
        status: false
    },
	confirmation: {
		user: false,
		email: false,
		phone: false
	},
	show: {
		social: false,
		address: false,
		email: false,
		phone: false,
		map: false,
		officePhone: false,
		webSite: false,
	},
	commission: 0,
	paymentInfo: {
		bank: '',
		iban: ''
	},
    languages:[]
};
export default {
    components: { VuePhoneNumberInput, VueBootstrapTypeahead },
    computed: {
        ...mapGetters({
            doctors: 'getDoctors',
            total: 'getDoctorTotal',
            pages: 'getDoctorPages',
            clinics: 'getClinics',
            clinic: 'getClinic',
            branchList: 'getBranchList',
            countries: 'getCountry',
            cities: 'getCity',
            userType: 'getUserType',
            profile: 'getProfile',
            setting: 'generalSetting'
        }),
        ...mapState('disease', {diseases: 'records'}),
		...mapState('treatment', {treatments: 'records'}),
		...mapState('extra', {languages: 'languages'}),
    },
    data() {
        const mapOptions = this.$root.defaultMapOptions;
        return {
            loadingPage: false,
            medisoft: {
                loading: false,
                list: []
            },
            view: 'table',
            editorOption: {
                modules: {
                    clipboard: {
                        matchVisual: true
                    },
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image']
                    ]
                },
                placeholder: ''
            },
            bankList: [
                'halkbank',
                'vakifbank',
                'ziraat',
                'akbank',
                'anadolubank',
                'fibabanka',
                'sekerbank',
                'turkish_bank',
                'turk_ekonomi_bankasi',
                'turkiye_is_bankasi',
                'yapi_kredi_bankasi',
                'adabank',
                'citibank',
                'denizbank',
                'garanti',
                'hsbc',
                'ing_bank',
                'odeabank',
                'finansbank'
            ],
            processModal: {
                status: false,
                index: false
            },
            imgUrl: process.env.VUE_APP_CDNURL + '/profile/',
            file: '',
            center: mapOptions.center,
            mapOptions: mapOptions,
            modal: {
                loading: false,
                status: false,
                showHtml: false,
                data: _.cloneDeep(emptyModal)
            },
            photoModal: {
                loading: false,
                status: false,
                data: {}
            },
            photos: {
				loading: false,
				list: [],
				files: null
			},
			diplomasAndCertificates: {
				loading: false,
				list: [],
				files: null
			},
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {},
            queryDiseases: '',
			selectedDisease: null,
			queryTreatments: '',
			selectedTreatment: null
        };
    },
    methods: {
        inputDisease(val){
			if(val == ''){
				this.selectedDisease = null
			}
		},
		addDisease(){
			this.modal.data.diseases.push(this.selectedDisease)
			this.$store.commit('disease/setRecords', [])
			this.$refs.selectedDisease.inputValue = ''
			this.queryDiseases = ''
			this.selectedDisease = ''
		},
		removeDisease(_id){
			Vue.delete(this.modal.data.diseases, _.findIndex(this.modal.data.diseases, {_id}))
		},
		inputTreatment(val){
			if(val == ''){
				this.selectedTreatment = null
			}
		},
		addTreatment(){
			this.modal.data.treatments.push(this.selectedTreatment)
			this.$store.commit('treatment/setRecords', [])
			this.$refs.selectedTreatment.inputValue = ''
			this.queryTreatments = ''
			this.selectedTreatment = ''
		},
		removeTreatment(_id){
			Vue.delete(this.modal.data.treatments, _.findIndex(this.modal.data.treatments, {_id}))
		},
        clearSpace(item, lang) {
            let text = _.clone(self.modal.data.descText[lang]);
            text = text.replace(/(?:\r\n|\r|\n)/g, ' ');
            text = text.replace(/ +(?= )/g, '');
            self.modal.data.descText[lang] = _.clone(text);
        },
        onPhone(payload) {
            if (payload.isValid) {
                this.$set(self.modal.data, 'countryCode', payload.countryCallingCode);
                this.$set(self.modal.data, 'phone', payload.nationalNumber);
            } else {
                this.$set(self.modal.data, 'countryCode', '');
                this.$set(self.modal.data, 'phone', '');
            }
        },
        onOfficePhone(payload) {
			if (payload.isValid) {
				this.$set(self.modal.data.officePhone, 'code', payload.countryCallingCode)
				this.$set(self.modal.data.officePhone, 'number', payload.nationalNumber)
			} else {
				this.$set(self.modal.data.officePhone, 'code', '')
				this.$set(self.modal.data.officePhone, 'number', '')
			}
		},
        openProcessModal(index) {
            self.processModal.index = index;
            self.processModal.status = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData(true);
            }, 800);
        },
        handleFileUpload() {
            self.file = this.$refs.file.files[0];
        },
        uploadPhoto() {
            self.photoModal.loading = true;
            self.$store
                .dispatch(DOCTOR_UPLOAD, { file: self.photoModal.data.upload, user: self.photoModal.data.user })
                .then(resp => {
                    self.photoModal.loading = false;
                    self.photoModal.status = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.photoModal.loading = false;
                    handleError(self, err);
                });
        },
        async uploadPictures(uuid, type) {
			this.modal.loading = true
			try {
				var response = await this.$store.dispatch('DOCTOR_PICTUREUPLOAD', {files: this[type].list, user: uuid, type})
				if (response.status) {
					var countPictures = this.modal.data[type].length
					response.pictures.forEach((item, index) => {
						Vue.set(this.modal.data[type], countPictures + index, item)
					})
				}
				this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
				this.$refs[type].reset()
				this.modal.loading = false
			} catch (err) {
				this.modal.loading = false
				handleError(this, err)
			}
		},
		async removePicture(user, picture, type) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch('DOCTOR_PICTUREDELETE', {user, picture, type})
					this.loading = false
					this.$delete(this.modal.data[type], this.modal.data[type].indexOf(picture))
					this.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		},
        addPhoto(row) {
            self.photoModal.loading = false;
            self.photoModal.status = true;
            self.photoModal.data = { user: row.uuid, upload: null };
            self.photoModal.data['photo'] = row.photo != null || row.photo != '' ? row.photo : false;
        },
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        generateSeo(key) {
            let lang = self.$i18n.locale;
            self.modal.data.seo = getSlug(self.modal.data[key], { lang });
        },
        changeCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            let marker = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        markerDrag(location) {
            let marker = { lat: location.latLng.lat(), lng: location.latLng.lng() };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        async handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);

            if(this.userType=='clinic' && this.clinic._id!==undefined){
                self.modal.data.institution = 'clinic';
                self.modal.data.clinic = this.clinic._id;
            }

            self.modal.data = _.merge(self.modal.data, row);
            _.forEach(self.modal.data.desc, (v, i) => {
                self.modal.data.desc[i] = v.replace(/[\\]/g, '');
            });
            let parsePhone = findPhoneNumbersInText('+' + self.modal.data.phone.toString());
            let country = parsePhone[0].number.country;
            let phone = parsePhone[0].number.nationalNumber;
            let countryCode = parsePhone[0].number.countryCallingCode;
            this.$set(self.modal.data, 'defaultCountryCode', country);
            this.$set(self.modal.data, 'countryCode', countryCode);
            this.$set(self.modal.data, 'maskPhone', phone);

            if (self.modal.data.location.lat && self.modal.data.location.lat != 0) {
                setTimeout(() => {
                    self.center = _.cloneDeep(self.modal.data.location);
                }, 500);
            }
        },
        photoModalClose() {
            self.photoModal.loading = false;
            self.photoModal.status = false;
            self.photoModal.data = {};
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        getData(page = false) {
            if (page) {
                self.pagination.page = 1;
            }
            self.loading = true;
            self.$store
                .dispatch(DOCTOR_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        onSubmit() {
            if (self.modal.data.city != '' && self.modal.data.location.lat == 0) {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_location') });

                return false;
            }

            if (self.modal.data.city == '') {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_city') });

                return false;
            }

            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let doctor = _.pickBy(self.modal.data, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(DOCTOR_UPDATE, { uuid, doctor })
                .then(resp => {
                    self.modal.loading = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
    },
    filters: {
        showCountry(value) {
            let country = _.get(
                _.filter(self.countries, item => {
                    return item._id == value;
                }),
                0
            );

            return country ? country.name : '';
        },
        showCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            return city ? city.name : '';
        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, '<br/> ');
        },
        showClinic(value) {
            let clinic = _.find(self.clinics, item => {
                return value == item._id;
            });

            return clinic && clinic.name != undefined ? clinic.name : '';
        }
    },
    watch: {
		queryDiseases: _.debounce(function (value) {
			this.$store.commit('disease/setFilter', {key: 'keyword', value})
			this.$store.dispatch('disease/list')
		}, 500),
		queryTreatments: _.debounce(function (value) {
			this.$store.commit('treatment/setFilter', {key: 'keyword', value})
			this.$store.dispatch('treatment/list')
		}, 500),
	},
    created() {
        self = this;
        self.getData();
        self.$store.dispatch(CLINIC_REQUEST,  { pagination: {limit: 9999,page: 1}, fields: '_id name privateClinic' });
    }
};
</script>
